<template>
  <div>
   <v-row class="ma-0">
    <template v-for="(item, index) in whoWeAreObj.heading">
      <v-img width="100%" :height="$vuetify.breakpoint.xlOnly ? 650 : $vuetify.breakpoint.lgOnly ?  600:  $vuetify.breakpoint.xsOnly ?  200 : ''"
        :src="item.whoweare_image" class="img-gradient-overlay-industries " :key="index">
        <v-container :fill-height="$vuetify.breakpoint.smAndUp" class="apply-index " :class="$vuetify.breakpoint.lgAndUp ? 'common-width': ''">
            <v-row  :style="$vuetify.breakpoint.xs ? 'height: 65%' : 'height: 50%'">
              <v-col cols="12" :class="$vuetify.breakpoint.smAndUp  ? 'mt-15' : ''">
                <h1 class="white--text font-weight-bold text-center"
                 :class="$vuetify.breakpoint.mdAndUp ? 'text-h1' : $vuetify.breakpoint.smAndDown ? 'text-h5' : ''">
                 {{item.title}}
                </h1>
                <p :class="[ $vuetify.breakpoint.xsOnly ? 'text-subtitle-2' : 'text-subtitle-1', 'white--text', 'text-center', 'pa-1', 'mt-4']"> {{item.description }}</p>
              </v-col>
            </v-row>
       </v-container>
      </v-img>
    </template>
   </v-row>
    <template v-for="(item, index) in whoWeAreObj.welcome_content">
      <v-row class="apply-background ma-0  pb-10" :key="`welcome-${index}`">
        <v-container :class="$vuetify.breakpoint.lgAndUp ? 'common-width': ''">
          <v-row justify="center" :class="$vuetify.breakpoint.xs ? '' : 'ma-0 pa-10'">
            <v-col xs="12" lg="6" xl="4" md="6" sm="8" class="pa-0">
              <v-img class="scaling-element" :height="$vuetify.breakpoint.xlOnly ? 400 : '' " src="../../../src/assets/images/who-we-are/team.png"> </v-img>
            </v-col>
            <v-col xs="12" lg="6" xl="8" md="6" sm="8" class="pa-0">
              <v-card flat class="apply-background">
                <v-container>
                <v-card-text>
                <v-card-title :style="$vuetify.breakpoint.xs ? 'color:#4B98D3 !important; font-size:25px' : 'color:#4B98D3 !important; font-size:30px'" class="font-weight-bold"> {{ item.welcome_title }} </v-card-title>
                  <v-card-subtitle class="text-subtitle-1 mt-1">{{ item.welcome_description }}</v-card-subtitle>
                  <v-card-subtitle :class=" [ $vuetify.breakpoint.smAndDown ? 'text-subtitle-2' : 'text-subtitle-1' , 'font-weight-bold']">{{ item.welcome_sub_description }}</v-card-subtitle>
                  <v-card-actions>
                      <v-btn tile class="text-capitalize white--text rounded-pill"
                      @click="$router.push(`${item.demo_link}`)"
                      width="150"
                      height="45"
                      color="#4B98D3"
                      :samll=" $vuetify.breakpoint.mdAndDown"
                      :x-large="$vuetify.breakpoint.lgAndUp"
                      >
                      <span class="no-text-transform"> Book a demo</span></v-btn>
                  </v-card-actions>
                </v-card-text>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col xs="12" lg="12" xl="12" md="6" sm="8">
              <h1 class="text-center font-weight-bold color-changes pa-4" :class="$vuetify.breakpoint.xs ? 'text-h5': 'text-h2' "> {{ item.sub_title }}</h1>
              <div class="text-subtitle-1 pa-2 text-center">{{ item.why_choose_description }}</div>
            </v-col>
          </v-row>
          <v-row justify="center" :class=" $vuetify.breakpoint.xsOnly ?  'apply-column' : ''">
            <v-col xs="12" lg="6" xl="4" md="6" sm="8" v-for="(item, index) in whoWeAreObj.business_metric" :key="index">
              <v-container class="pa-4">
                <v-card height="470"
                  :style="{ border: `2px solid ${item.color}`, 'box-shadow': `7px 7px  ${item.color}` }"
                  class="rounded-xl">
                  <v-row class="ma-0 ">
                    <v-container>
                      <v-card-title class="">
                        <div class="circular-div  text-h6 font-weight-bold"
                          :style="{background: item.color}">
                          <div class="mt-2 ml-1 font-weight-bold text-h4"
                            style="position:relative !important;right:23px">{{ item.number }}</div>
                        </div>
                      </v-card-title>
                      <v-card-text class="pb-0">
                        <span :class="['ml-2','font-weight-bold']">{{ item.title }} </span>
                      </v-card-text>
                      <v-card-text class="pa-2">
                       <div v-html="item.business_metric_description" class="who-we-are-font-size" ></div>
                      </v-card-text>
                    </v-container>
                  </v-row>
                </v-card>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
    </template>
  </div>
</template>
<script>
import { api } from '../../plugins/axios'
export default {
  data () {
    return {
      colors: ['#FFE5E5', '#EFDCFF', '#C1F0E2'],
      whoWeAreObj: {}
    }
  },
  mounted () {
    this.getWhoWeAre()
    this.scrollToTop()
  },
  methods: {
    getWhoWeAre () {
      api.get('/who_we_are')
        .then((response) => {
          this.whoWeAreObj = response.data
          if (this.whoWeAreObj && this.whoWeAreObj.business_metric) {
            this.whoWeAreObj.business_metric.forEach((element, index) => {
              element.color = this.colors[index]
            })
          }
        })
        .catch((error) => {
          console.log(error.message)
          if (error.response && error.response.status === 500) {
            this.$router.push('/technical-difficulties')
          }
        })
    },
    scrollToTop () {
      // Scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optional: Adds smooth scrolling behavior
      })
    }
  }
}
</script>
<style>

.apply-background {
   background: #EDF5FB !important;
}
.circular-div {
  width: 58px; /* Set the width and height to make a square */
  height: 58px;
  border-radius: 50%; /* Set border-radius to 50% for a circular shape */
}

.apply-column {
  display: flex !important;
  flex-direction: column !important;
}
.who-we-are-font-size {
  font-size: 0.9em !important;
}
</style>
